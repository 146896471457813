// src/Footer.js
import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const Footer = () => {
  return (
    <Box 
      component="footer"
      sx={{
        bgcolor: '#DEA035',
        py: { xs: 1, sm: 2 }, // Adjust padding for different screen sizes
        position: 'fixed',
        bottom: 0,
        width: '100%',
        textAlign: 'center'
      }}
    >
      <Container maxWidth="lg"> {/* You can adjust maxWidth as needed */}
        <Typography 
          variant="body2" 
          color="textSecondary" 
          sx={{ 
            fontSize: { xs: '0.5rem', sm: '0.75rem' } // Adjust font size for different screen sizes
          }}
        >
          © 2024 Pustak Dukaan. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
