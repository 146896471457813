import React from 'react';
import './css/card.css';

const Card = ({ imageSrc, imageAlt, title, description, buttonLink, buttonText }) => {
  return (
    <div className="card">
      <img src={imageSrc} alt={imageAlt} className="card-image" />
      <div className="card-content">
        <h2>{title}</h2>
        <p>{description}</p>
        <a href={buttonLink} className="card-button">{buttonText}</a>
      </div>
    </div>
  );
};

export default Card;
