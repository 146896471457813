import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Grid from '@mui/material/Grid';
import logo from '../images/logo.png';
import { useAuth0 } from "@auth0/auth0-react";

// Base styled component for the search bar
const baseSearchStyles = (theme) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#ffffff',
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
});
// Styled component for desktop search
const Search = styled('div')(({ theme }) => ({
  ...baseSearchStyles(theme),
  '@media (max-width: 488px)': {
    display: 'none',
  },
}));

// Styled component for mobile search
const SearchMobile = styled('div')(({ theme }) => ({
  ...baseSearchStyles(theme),
  display: 'none',
  '@media (max-width: 488px)': {
    display: 'block',
  },
}));

// Styled component for the search icon container
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// Styled component for the input base
const StyledInputBase = styled(InputBase)(({ theme, hasValue }) => ({
  color: '#000000', // Change text color in the search bar
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    fontSize: hasValue ? '0.875rem' : '1rem', // Adjust font size based on value presence
    [theme.breakpoints.up('md')]: {
      width: '30ch', // Adjust search bar width
    },
    '@media (max-width: 488px)': {
      height: '4px',
      fontSize: hasValue ? '0.65rem' : '0.7rem', // Adjust font size on mobile
    },
  },
}));

const NavBar = () => {
  const {user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  return (
    <AppBar position="static" sx={{ backgroundColor: '#DEA035' }}> {/* Change AppBar color */}
      <Toolbar>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item sx={{ paddingRight: { lg: 4 } }}>
            <img src={logo} alt="logo" style={{ height: '60px' }} />
          </Grid>
          <Grid item xs={0} sx={{ mx: 3 }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: '#000000' }} /> {/* Change search icon color */}
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Grid>
          <Grid item sx={{ ml: 2 }}>
            {!isAuthenticated ? (
              <Button
                startIcon={<AccountCircleOutlinedIcon fontSize="large" sx={{ color: '#000000' }} />} // Icon inside button
                sx={{ color: '#ffffff', textTransform: 'none' }} // Text color and styling
                aria-label="login"
                onClick={() => loginWithRedirect()}
              >
                <Typography variant="body1" sx={{ color: '#000000' }}>
                  Login
                </Typography>
              </Button>
            ) : (
              <Button
                sx={{ color: '#ffffff', textTransform: 'none' }} // Text color and styling
                aria-label="logout"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                {isAuthenticated && (
                  <div style={{ marginTop: '12px' }}>
                    <img src={user.picture} alt="User Profile" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                  </div>
                )}
              </Button>
            )}
          </Grid>
        </Grid>
      </Toolbar>
      <Grid container justifyContent="center" alignItems="center">
        <SearchMobile>
          <SearchIconWrapper>
            <SearchIcon sx={{ color: '#000000' }} /> {/* Change search icon color */}
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
          />
        </SearchMobile>
      </Grid>
    </AppBar>
  );
};

export default NavBar;