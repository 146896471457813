import React from "react";
import Card from "./Card";
import buyNowImage from './images/buy_book.jpg'
import sellNowImage from './images/sell_book.jpg'

const HomePage = () => {
    return (
        <div className="page-container"> {/* Outer wrapper div */}
        <div className="card-wrapper"> {/* Centering wrapper div */}
          <div className="card-container">
            <Card
              imageSrc={buyNowImage}
              imageAlt="Buy Book"
              title="Buy Book"
              description="Explore a wide range of books and find the best deals."
              buttonLink="/buy"
              buttonText="Shop Now"
            />
  
            <Card
              imageSrc={sellNowImage}
              imageAlt="Sell Book Online"
              title="Sell Book"
              description="Turn your books into cash quickly and easily."
              buttonLink="/sell"
              buttonText="Start Selling"
            />
          </div>
        </div>
      </div>
    );
}

export default HomePage