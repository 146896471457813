import React from 'react';
import { useAuth0} from '@auth0/auth0-react';
import './App.css';
import NavBar from './components/NavBarTop';
import Footer from './components/Footer';
import HomePage from './components/HomePage';

const App = () => {
  const {user, isAuthenticated } = useAuth0();

  return (
    <div className="App">
      <NavBar />
      <HomePage />
      <Footer />
    </div>
  );
}

export default App;
